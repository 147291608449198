//const swup = require('swup'); // only this line when included with script tag

export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('common');
    
    
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    // new Swup(); // only this line when included with script tag§
  },
};
