import $ from 'jquery';
import './style.scss';
import 'flexboxgrid2';

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';

import Swup from 'swup';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Sketch from 'sketch-js';


AOS.init();

function openInNewPage() {
  var urlNewPage = document.querySelector(".link");
  urlNewPage.addEventListener("click", function(){
    window.open('', '_blank');
  });
}

const swup = new Swup({
  plugins: [new SwupBodyClassPlugin(), new SwupPreloadPlugin(), new SwupScrollPlugin()]
});

let scrollValues = {};

swup.on('willReplaceContent', () => {
  scrollValues[window.location.href] = window.scrollTo(0,0); 
});

swup.on('animationOutDone', () => {
  demo.setup();
});

swup.on('scrollDone', () => {
  demo.setup();
});

swup.on('contentReplaced', () => {
  bubblesOnPhotoHover();
});

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** Home page */
  home
  /** About Us page, note the change from about-us to aboutUs. */
});


// CHANGE BACKGROUND COLOR WHILE SCROLLING

/** Load Events */
$(document).ready(() => routes.loadEvents());

$(window).scroll(function() {
  
  // selectors
  var $window = $(window),
      $body = $('body'),
      $panel = $('.panel');
  
  // Change 33% earlier than scroll position so colour is there when you arrive.
  var scroll = $window.scrollTop() + ($window.height() / 2);
 
  $panel.each(function () {
    var $this = $(this);
    
    // if position is within range of this panel.
    // So position of (position of top of div <= scroll position) && (position of bottom of div > scroll position).
    // Remember we set the scroll to 33% earlier in scroll var.
    if ($this.position().top <= scroll && $this.position().top + $this.height() > scroll) {
          
      // Remove all classes on body with color-
      $body.removeClass(function (index, css) {
        return (css.match (/(^|\s)color-\S+/g) || []).join(' ');
      });
       
      // Add class of currently active div
      $body.addClass('color-' + $(this).data('color'));
    }
  });    
  
}).scroll();

$(document).ready(function () {
  $(window).scroll(function () {
    var ScrollTop = parseInt($(window).scrollTop());

    if (ScrollTop == 0) {
      $(".logo-icon-top").fadeOut();
    }
    else if (ScrollTop > 100) {
      $(".logo-icon-top").fadeIn();
    }
  });
});



  // ----------------------------------------
  // BUBBLES              
  // ----------------------------------------

  function Particle( x, y, radius ) {
    this.init( x, y, radius );
}

Particle.prototype = {

    init: function( x, y, radius ) {

        this.alive = true;

        this.radius = radius || 10;
        this.wander = 0.15;
        this.theta = random( TWO_PI );
        this.drag = 0.92;
        this.color = '#fff';

        this.x = x || 0.0;
        this.y = y || 0.0;

        this.vx = 0.0;
        this.vy = 0.0;
    },

    move: function() {

        this.x += this.vx;
        this.y += this.vy;

        this.vx *= this.drag;
        this.vy *= this.drag;

        this.theta += random( -0.5, 0.5 ) * this.wander;
        this.vx += sin( this.theta ) * 0.1;
        this.vy += cos( this.theta ) * 0.1;

        this.radius *= 0.96;
        this.alive = this.radius > 0.5;
    },

    draw: function( ctx ) {

        ctx.beginPath();
        ctx.arc( this.x, this.y, this.radius, 0, TWO_PI );
        ctx.fillStyle = this.color;
        ctx.fill();
    }
};

// ----------------------------------------
// Example
// ----------------------------------------

var MAX_PARTICLES = 100;
var COLOURS = [ '#69D2E7', '#A7DBD8', '#E0E4CC', '#F38630', '#FA6900', '#FF4E50', '#F9D423' ];

var particles = [];
var pool = [];

var demo = Sketch.create({
    //container: document.getElementById( 'project-hero' )
    retina: 'auto'
});

demo.setup = function() {

    // Set off some initial particles.
    var i, x, y;

    for ( i = 0; i < 20; i++ ) {
        x = ( demo.width * 0.5 ) + random( -100, 100 );
        y = ( demo.height * 0.5 ) + random( -100, 100 );
        demo.spawn( x, y );
    }
};

demo.spawn = function( x, y ) {
    
    var particle, theta, force;

    if ( particles.length >= MAX_PARTICLES )
        pool.push( particles.shift() );

    particle = pool.length ? pool.pop() : new Particle();
    particle.init( x, y, random( 5, 40 ) );

    particle.wander = random( 0.5, 2.0 );
    particle.color = random( COLOURS );
    particle.drag = random( 0.9, 0.99 );

    theta = random( TWO_PI );
    force = random( 2, 8 );

    particle.vx = sin( theta ) * force;
    particle.vy = cos( theta ) * force;

    particles.push( particle );
};

demo.update = function() {

    var i, particle;

    for ( i = particles.length - 1; i >= 0; i-- ) {

        particle = particles[i];

        if ( particle.alive ) particle.move();
        else pool.push( particles.splice( i, 1 )[0] );
    }
};

demo.draw = function() {

    demo.globalCompositeOperation  = 'lighter';

    for ( var i = particles.length - 1; i >= 0; i-- ) {
        particles[i].draw( demo );
    }
};

// demo.mousemove = function() {

//     var particle, theta, force, touch, max, i, j, n;

//     for ( i = 0, n = demo.touches.length; i < n; i++ ) {

//         touch = demo.touches[i], max = random( 1, 4 );
//         for ( j = 0; j < max; j++ ) {
//           demo.spawn( touch.x, touch.y );
//         }

//     }
// };


// rotating logo

window.onscroll = function () {
  scrollRotate();
};

function scrollRotate() {
  let image = document.getElementById("circular-logo");
  image.style.transform = "rotate(" + window.pageYOffset/10 + "deg)";
}

// bubbles on logo hover

function bubblesOnPhotoHover() {
  let dots = document.getElementById("dots");
  let canvas = document.querySelector("canvas");
  dots.addEventListener("click", function (event) {
    console.log('hover');
    demo.setup();
  }, false);
  dots.addEventListener("mouseenter", function (event) {
    canvas.style.position = "fixed";
  }, false);
  dots.addEventListener("mouseleave", function (event) {
    canvas.style.position = "absolute";
  }, false);
}

bubblesOnPhotoHover();


// active class nav item

// window.addEventListener('DOMContentLoaded', () => {

//   const observer = new IntersectionObserver(entries => {
//     entries.forEach(entry => {
//       const id = entry.target.getAttribute('id');
//       if (entry.intersectionRatio == 0.5) {
//         document.querySelector(`nav a[href="#${id}"]`).classList.add('active');
//       } else {
//         document.querySelector(`nav a[href="#${id}"]`).classList.remove('active');
//       }
//     });
//   });

//   // Track all sections that have an `id` applied
//   document.querySelectorAll('section[id]').forEach((section) => {
//     observer.observe(section);
//   });

// });

$(document).ready(function () {

  $(window).scroll(function () {
    let scrollPos = $(this).scrollTop();
    let scrollDistance = scrollPos;
    
    $("nav a[href^='#']").each(function () {
      let currLink = $(this);
      let refElement = $(currLink.attr("href"));
      if (refElement.position().top <= scrollDistance && refElement.position().top + refElement.outerHeight() > scrollDistance) {
        $("nav a").removeClass("active");
        currLink.addClass("active");
      }
      else {
        currLink.removeClass("active");
      }
    });
  });

});